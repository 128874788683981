.audio-converter {
    margin: 200px auto;
    width: fit-content;
}

.audio-converter label.drop {
    border: 2px dashed #00345e80;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    width: fit-content;
    flex-direction: column;
}

.audio-converter label.drop:hover {
    background-color: white;
    border-color: #00345e;
}

.audio-converter svg {
    margin-bottom: 20px;
}
